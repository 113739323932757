* { 
  box-sizing: border-box; 
} 
 
.App { 
  text-align: center; 
} 
 
.App-logo { 
  animation: App-logo-spin infinite 20s linear; 
  height: 40vmin; 
  pointer-events: none; 
} 
 
.App-header { 
  background-color: #282c34; 
  min-height: 100vh; 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  font-size: calc(10px + 2vmin); 
  color: white; 
} 
 
.App-link { 
  color: #61dafb; 
} 
 
a { 
  text-decoration: none; 
} 
 
@keyframes App-logo-spin { 
  from { 
    transform: rotate(0deg); 
  } 
  to { 
    transform: rotate(360deg); 
  } 
} 
 
.navbar-wrapper { 
  background: white; 
 
  header { 
    background: white; 
  } 
 
  img { 
    float: left; 
    width: 44px; 
    margin-top: 4px; 
  } 
 
  .MuiToolbar-regular { 
    padding: 0; 
  } 
 
  .title { 
    display: block; 
    flex-grow: 1; 
  } 
 
  .title-text { 
    float: left; 
    padding-top: 10px; 
    padding-left: 14px; 
  } 
 
  .menu-button { 
    display: flex; 
 
    .page-title { 
      margin-top: 8px; 
    } 
  } 
 
  .menu-button button { 
    display: flex; 
    position: relative; 
    margin-left: 0; 
    color: #1CBAE7; 
    font-size: 16px; 
    margin-left: 20px; 
  } 
 
  .menu-button button:hover { 
    background: transparent; 
  } 
 
  .menu-button .blue-btn { 
    background: #1CBAE7; 
    color: white; 
    padding: 5px 30px; 
    text-transform: capitalize; 
    font-weight: normal; 
    border-radius: 10px; 
   
  } 
 
  .user-menu { 
    display: flex; 
    align-items: center; 
    justify-content: center; 
  } 
} 
 
/***** END OF ADMIN PORTAL *****/ 
 
@media screen and (max-width: 768px) { 
  #root .MuiContainer-root { 
    padding-left: 22px; 
    padding-right: 22px; 
  } 
 
  .navbar-wrapper .title-text { 
    font-size: 14px;
    margin-top: 3px;
    margin-left: -5px;
  } 
 .page-title { 
   font-size: 12px;
   margin-top: 6px!important;
 } 
 .menu-button .blue-btn { 
  padding: 4px 20px!important; 
  border-radius: 10px; 
  font-size: 10px!important;
 
} 
} 

.alert {
  min-width: 150px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 3px;
  @extend .shadow-1;
  &:hover{
    @extend .shadow-2;
  }
  &-success{
    background-color: lighten(#5cb85c, 15%);
    border-color: lighten(#5cb85c, 10%);
    color: darken(#5cb85c, 15%);
  }
  &-danger{
    background-color: lighten(#d43f3a, 15%);
    border-color: lighten(#d43f3a, 10%);
    color: darken(#d43f3a, 15%);
  }
  p{
    padding: 0;
    margin: 0;
  }
  i{
    padding-right: 5px;
    vertical-align: middle;
    font-size: 24px;
  }
  .close-alert{
    -webkit-appearance: none;
    position: relative;
    float: right;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: inherit;
    background: 0 0;
    font-size: 21px;
    line-height: 1;
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(255,255,255,.7);
    filter: alpha(opacity=40);
    opacity: .4;
    &:hover{
      filter: alpha(opacity=70);
      opacity: .7;
    }
  }
}

.shadow-1{
  box-shadow: 0 1px 3px rgba(#000, .12), 0 1px 2px rgba(#000, .24) ;
}
.shadow-2{
  box-shadow: 0 3px 6px rgba(#000, .16), 0 3px 6px rgba(#000, .23) ;
}