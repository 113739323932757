.App {
  text-align: center;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.link{
  color: white!important;
  text-decoration: none!important; 
}
.custom-file-input {
 color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
 visibility: hidden;
 text-align: center;
}
.custom-file-input::before {
 content: 'Select Input';
 color: black;
 display: inline-block;
 background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
 border: 1px solid #999;
 border-radius: 3px;
 padding: 5px 50px;
 outline: none;
 white-space: nowrap;
 -webkit-user-select: none;
 cursor: pointer;
 text-shadow: 1px 1px #fff;
 font-weight: 700;
 font-size: 10pt;
 text-align: center;
 margin-left: 15%;
 height: auto;
 
}
.custom-file-input:hover::before {
 border-color: black;
}
.custom-file-input:active {
 outline: 0;
}
.custom-file-input:active::before {
 background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}
.apiBtn{
  margin-top:2px;
}
.responsive {
 width: 6%;
 height: auto;
 margin-left: 20px;
 margin-top: 30px;
}
.imgresponsive {
  width: 12%;
  height: auto;
  margin-left: 20px;
  max-width: 200px;
  max-height: 200px;
 }
 .imgresponsive2 {
  width: 10%;
  height: auto;
  margin-left: 10px;
 }
 .imgresponsive3{
   max-width: 600px;
   width: 30%;
   height: auto;
   margin-left: 10px;
 }
 .iconeresponsive{
  width: 10%;
  height: auto;
  margin-left: 20px;
  max-height: 100px;
  min-width: 40px;
 }
 .iconresponsive2 {
  width: 6%;
  height: auto;
  margin-left: 10px;
 }
 .videoresponsive{
  width: 20%;
  height: auto;
  margin-left: 20px;
 }
 .videoresponsive2{
  width: 40%;
  height: auto;
  margin-left: 20px;
  text-align: center!important;
 display: inline-block
 }
 .videoresponsive2Edit{
  width: 60%;
  height: auto;
  margin-left: 20px;
  text-align: center!important;
 display: inline-block
 }
.objposition{
 text-align: center!important;
 display: inline-block
}
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width:  30%;
  background-color: #000; }
  .ReactCrop:focus {
    outline: none; }
  .ReactCrop--disabled {
    cursor: inherit; }
  .ReactCrop__image {
    display: block;
    max-width: 100%;
    max-height: -webkit-stretch;
    max-height: -moz-available;
    max-height: stretch; }
  .ReactCrop--crop-invisible .ReactCrop__image {
    opacity: 0.5; }
  .ReactCrop__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    cursor: move;
    box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
    border: 1px solid;
    border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
    border-image-slice: 1;
    border-image-repeat: repeat; }
    .ReactCrop--disabled .ReactCrop__crop-selection {
      cursor: inherit; }
  .ReactCrop__drag-handle {
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    outline: 1px solid transparent; }
  .ReactCrop .ord-nw {
    top: 0;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: nw-resize; }
  .ReactCrop .ord-n {
    top: 0;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    cursor: n-resize; }
  .ReactCrop .ord-ne {
    top: 0;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: ne-resize; }
  .ReactCrop .ord-e {
    top: 50%;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: e-resize; }
  .ReactCrop .ord-se {
    bottom: 0;
    right: 0;
    margin-bottom: -5px;
    margin-right: -5px;
    cursor: se-resize; }
  .ReactCrop .ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: s-resize; }
  .ReactCrop .ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: sw-resize; }
  .ReactCrop .ord-w {
    top: 50%;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: w-resize; }
  .ReactCrop__disabled .ReactCrop__drag-handle {
    cursor: inherit; }
  .ReactCrop__drag-bar {
    position: absolute; }
    .ReactCrop__drag-bar.ord-n {
      top: 0;
      left: 0;
      width: 100%;
      height: 6px;
      margin-top: -3px; }
    .ReactCrop__drag-bar.ord-e {
      right: 0;
      top: 0;
      width: 6px;
      height: 100%;
      margin-right: -3px; }
    .ReactCrop__drag-bar.ord-s {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      margin-bottom: -3px; }
    .ReactCrop__drag-bar.ord-w {
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      margin-left: -3px; }
  .ReactCrop--new-crop .ReactCrop__drag-bar,
  .ReactCrop--new-crop .ReactCrop__drag-handle,
  .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
    display: none; }
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
    display: none; }
  @media (max-width: 768px), (pointer: coarse) {
    .ReactCrop__drag-handle {
      width: 17px;
      height: 17px; }
    .ReactCrop .ord-nw {
      margin-top: -9px;
      margin-left: -9px; }
    .ReactCrop .ord-n {
      margin-top: -9px;
      margin-left: -9px; }
    .ReactCrop .ord-ne {
      margin-top: -9px;
      margin-right: -9px; }
    .ReactCrop .ord-e {
      margin-top: -9px;
      margin-right: -9px; }
    .ReactCrop .ord-se {
      margin-bottom: -9px;
      margin-right: -9px; }
    .ReactCrop .ord-s {
      margin-bottom: -9px;
      margin-left: -9px; }
    .ReactCrop .ord-sw {
      margin-bottom: -9px;
      margin-left: -9px; }
    .ReactCrop .ord-w {
      margin-top: -9px;
      margin-left: -9px; }
    .ReactCrop__drag-bar.ord-n {
      height: 14px;
      margin-top: -7px; }
    .ReactCrop__drag-bar.ord-e {
      width: 14px;
      margin-right: -7px; }
    .ReactCrop__drag-bar.ord-s {
      height: 14px;
      margin-bottom: -7px; }
    .ReactCrop__drag-bar.ord-w {
      width: 14px;
      margin-left: -7px; } 
    .ReactCrop {
        max-width:  60%!important;
      }
}
.sticky{
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%
}
.canvaRef{
  visibility: hidden;
  display: none;
}
.imgDrop{
  text-align: center;
  display: inline-block
}
.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
}

.title {
  margin-top: 20px;
  text-align: center;
  font-family: "Roboto"!important;
}
.title2 {
  margin-top: 20px;
  margin-left: -20px;
  color: #02457A;
  font-size: 28px;
  font-family: "Roboto"!important;
}
.loader{
  color: #02457A;
  font-size: 14px;
  font-family: "Roboto"!important;
}
.apiBtnTitle{
  font-family: "Roboto"!important;
}
.apiBtnLink{
  font-family: "Roboto"!important;
}
tr {
  height: 40px !important;
}

th {
  max-width: 125px;
  min-width: 125px;
  padding: 0 !important;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center !important;
}

td {
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
}
.listImg{
  float: left;
  width: 165px;
  height: 165px;
}
.list{
  max-width: 165px;
  max-height: 165px;
  width: auto;
  height: auto;
}
.listSpace{
  display: flex;
  justify-content: center;
}
.button1 {
  width: 150px;
  height: 150px;
  margin-left: 10px;
  background-image:  url('/assets/img/img1.png') ;
  background-size: cover;
}
.button2 {
  margin-left: 10px;
  width: 150px;
  height: 150px;
  background-image:  url('/assets/img/img2.png') ;
  background-size: cover;
}
.button3 {
  margin-left: 10px;
  width: 150px;
  height: 150px;
  background-image:  url('/assets/img/img3.png') ;
  background-size: cover;
}
.loadingText{
  font-size: 16px;
  font-family: "Roboto"!important;
}
.containerStyleHeader{
  background-color: white;
  height: 80px;
  border-radius: 12px;
}
.containerStyleNewExp{
  background-color: white;
  height: 80vh;
  border-radius: 12px; 
}
.containerStyleNewAR{
  background-color: white;
  height: 165px;
}
.containerStyleBody{
  background-color: white;
  height: auto;
  border-radius: 12px;
}
.title{
  float: left;
  vertical-align: middle;
  margin-left:30px!important;
  color:  #02457A!important;
  font-size: 30px;
  font-family: "Roboto"!important; 
}
.arButton {
  vertical-align: middle;
  background-color:#00BCEB!important;
  color: #fff!important;
  margin-top:19px!important;
  margin-right:30px!important;
  float: right;
  font-family: "Roboto"!important;
}
.arButton2 {
  background-color: #00BCEB!important;
  color: #fff!important;
  font-family: "Roboto"!important;
}
.arButton3 {
  background-color:#096799!important;
  color: #fff!important;
  padding: 0px 26px!important;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto"!important;
}
.arButton8 {
  background-color:#949393!important;
  color: #fff!important;
  padding: 0px 26px!important;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto"!important;
}
.arButton9 {
  background-color:#096799!important;
  color: #fff!important;
  padding: 0px 26px!important;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto"!important;
  height: 35px!important;
}
.arButton10 {
  background-color:#949393!important;
  color: #fff!important;
  padding: 0px 26px!important;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto"!important;
  height: 35px!important;
}
.clearBtn{
  background-color:#096799!important;
  color: #fff!important;
  padding: 0px 26px!important;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto"!important;
}
.arButton4 {
  background-color:rgb(65, 207, 177)!important;
  color: #fff!important;
  padding: 0px 26px!important;
  font-family: "Roboto"!important;
}
.arButton11{
  background-color:#949393!important;
  color: #fff!important;
  padding: 0px 26px!important;
  font-family: "Roboto"!important;
}
.arButton5 {
  background-image: linear-gradient(to right, #786CD1 , #AFA8E7);
  color: #fff!important;
  padding: 0px 26px!important;
  font-family: "Roboto"!important;
}
.cardField {
  /* min-width: 1210px!important; */
  width: 1210px;
  min-width: auto!important;
}

.arList{
  text-align: center!important;
  display: inline-block!important;
}
.imageDisplay{
  text-align: left!important;
  display: inline-block!important;
}
.arObjectTitle{
  text-align: left;
  margin-left: 30px!important;
  color: #2d4c6e!important;
  font-family: "Roboto"!important;
}
.arObjectDate{
  text-align: left;
  margin-left: 30px!important;
  color: #2d4c6e!important;
  font-family: "Roboto"!important;
  margin-top: -40px;
  margin-bottom: -40px!important;
}
.arObjText{
  margin-top:-30px!important;
}
.arObjectText{
   margin-left: 30px;
   color: #2d4c6e!important;
   font-family: "Roboto"!important;
   text-align: left;   
}
.arObjectText2{
  color: #2d4c6e!important;
  font-family: "Roboto"!important;
}
.arObjectText3{
  text-align: right;
  margin-right: 10px;
  color: #2d4c6e!important;
   font-family: "Roboto"!important;

}
.listItem{
  cursor: pointer;
}
.tdText{ width: 600px;}
.tdText2{width: 200px}
.tdText3{width: 400px;}
.arObjectClick{
  float: right;
  color: #2d4c6e!important;
}
.dropImage{
  max-width: 400px!important;
  max-height: 300px!important;
  image-orientation: none!important;
}
.dropImageEdit{
  max-width: 400px!important;
  max-height: 300px!important;
  image-orientation: none!important;
}
.arimgresponsive{
  max-width: 400px;
  width: 40%;
  height: auto;
}
.arimgresponsive2{
  max-width: 400px;
  max-height: 300px;
  width: auto;
  height: auto;
}
.arExperienceTitle{
  color: #135291!important;
  font-family: "Roboto"!important;
}
.effPeriodTitle{
  color:#02457A!important;
}
.datePicker{
  background-color: #1EC6E0!important;
}
.divHeader{
  background-image: linear-gradient(to right, #02457A , #1AB4DF, #25EECC);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 120px;
}
.dateFormat{
  font-family: "Roboto"!important;
  font-size: 20px;
}
.titleName{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    background-image: linear-gradient(to right, #02457A , #1AB4DF, #25EECC);
    color: white;
    font-size: 28px;
    margin-top:20px!important;
    font-family: "Roboto"!important;
}
::placeholder { 
  color: white; 
} 
.iconActive{
  background-color: #23E5D0!important;
}
.iconNonActive{
 background-color: #B4B4B4!important;
}
.iconDet{
  margin-left: 10%!important;
}
.backIcon{
 float: left;
 margin-left: 10%; 
 cursor: pointer;
}
.backIcon2{
  margin-top: 120%!important;
  float: left;
  margin-left: 10%;
  cursor: pointer;
 }
.nextIcon{
  margin-right: 5%;
  float: right;
}
.nextIcon4{
  margin-right: 3%;
  float: right;
}
.icon7Size{
  width: 50%!important;
  height: 50%!important;
}

.processTitle{
  display: inline-block;
  color:  rgb(255, 255, 255)!important;
  font-size: 26px;
  font-family: "Roboto"!important;
  margin-left: 5%;
}
.processTitleDiv{
  text-align: left!important;
}
.apiAction{
  margin-left: -300px;
}

.dropZone{
  border-color: gray;
  border-style: dashed;
  border-width: 5px;
  width: 600px;
  height: 300px;
  border-radius: 12px;
  background-image:  url('/assets/img/img8.png') ;
  background-size: cover;
}
.dropZone2{
  border-color: gray;
  border-style: dashed;
  border-width: 5px;
  width: 600px;
  height: 300px;
  border-radius: 12px;
  background-image:  url('/assets/img/img9.png') ;
  background-size: cover;
}
.dropZone3{
  border-color: gray;
  border-style: dashed;
  border-width: 5px;
  width: 600px;
  height: 300px;
  border-radius: 12px;
  background-image:  url('/assets/img/img10.png') ;
  background-size: cover;
}
.imgAssetResp {
  max-width: 200px;
  width: 40%;
  height: auto;
  margin-left: 80px;
  margin:2px;
 }
 .iconBtnSize{
   width: 80px;
   height: 80px;
 }
 .arrowDirection{
  margin-bottom: 40px;
  margin-left: -50px;
 }
 .iconImgPlace{
  margin-bottom: 80px !important;
 }
 .iconImgPlace2{
  margin-bottom: 80px !important;
  margin-left: 30px!important;
 }
 .iconImgPlaceEdit{
  margin-left: 30px!important;
  margin-right: 30px!important;
  margin-bottom: 80px !important;
 }
 .editButtongTable{
  display: inline-block;
  justify-content: center;
 }
 .ButtonTable{
  display: inline-block;
  justify-content: center;
 }
 .tbasset{
   margin-left: -2%;
 }
.assetTypeText{
  font-size: 20px;
  margin-top: -65px;
  font-family: "Roboto"!important;
}
.assetTypeText2{
  font-size: 20px;
  margin-right: -30px;
  margin-top: -65px;
  font-family: "Roboto"!important;
}
.ProgressBar{
  text-align: center!important;
 display: inline-block;
}
.effDateText{
  font-family: "Roboto"!important;
}
.iconRange{
  margin-right: 65px!important;
  margin-top: -1px;
  font-family: "Roboto"!important;
}
.iconRange2{
  margin-right: 55px!important;
  margin-top: -1px;
  font-family: "Roboto"!important;
}
.triggerAssetText{
  display:none;
  visibility: hidden;
  text-align: center!important;
}
.imgAsset{
  max-width: 180!important;
  height:80px!important;
}
.datePickerTb{
  text-align: center!important;
  display: inline-block!important;
}
::placeholder {
  color: black;
}
.hide{
  display: none;
  visibility: hidden;
}
.hide2{
  visibility: hidden;
}
.liveText{
  color: rgb(28, 164, 209)!important;
}
.draftText{
  color: rgb(173, 169, 169)!important;
}
@media screen and (max-width: 550px) {
  .arButton8{
    font-size: 8px!important;
    padding: 0px 14px!important;
  }
  .arButton3{
    font-size: 8px!important;
    padding: 0px 14px!important;
  }
  .arButton9{
    font-size: 8px!important;
    padding: 0px 14px!important;
    width: 90px!important;
    height: 35px!important;
  }
  .arButton10{
    font-size: 8px!important;
    padding: 0px 14px!important;
    width: 90px!important;
    height: 35px!important;
  }
  .arButton4{
    font-size: 8px!important;
    padding: 0px 14px!important;
  }
  .arButton11{
    font-size: 8px!important;
    padding: 0px 14px!important;
  }
  .iconSize{
    width:15px!important;;
    height: 10px!important;
    margin-top:15px!important;
  }
  .dropZone2{
    width: 300px!important;
    height: 150px!important;
  }
  .dropZone3{
    width: 300px!important;
    height: 150px!important;
  }
  .nextIcon{
     margin-top:30px;
  }
  .backIcon{
   margin-right: 20%;
  }
  .dropImage{
    max-width: 300px!important;
    max-height: 150px!important;
    width: auto!important;
  }
  .dropImageEdit{
    max-width: 200px!important;
    max-height: 150px!important;
    width: auto!important
  }
  .backIconImg{
    width: 20px!important;
    height: 20px!important;
   } 
  .effDateText{
    font-size: 14px !important;
  }
  .arObjectTitle{
    font-size: 12px !important;
    margin-left:auto !important;
    margin-bottom: 30px!important;
  }
  .arObjText{
    margin-top:0px!important;
  }
  .tdText3{width: 100px!important;}
  .title{
    font-size: 20px;
  }
  .arButton {
    width: 120px!important;
    height: 40px!important;
    font-size: 10px!important;
  }
  .arObjectText{
    font-size: 8px!important;
    margin-left: 0px;
 }
 .arObjectText2{
   font-size: 8px!important;
 }
 .arObjectText3{
   font-size: 8px!important;
 }
 .arObjectDate{
  font-size: 8px!important;
  margin-left:auto !important;
}
 .iconImgPlaceEdit{
  margin-left: auto!important;
  margin-right: auto!important;;
 }
 .arimgresponsive2{
  max-width: 300px;
  max-height: 300px;
}
}
@media screen and (max-width: 670px) {
  .arObjectDate{
    font-size: 12px;
  }
  .divHeader{
    height: 110px!important;
  } 
  .titleName{
    font-size: 14px!important;
  }
  .processTitle{
    font-size: 18px!important;
  }
  .iconSize{
    width:40px!important;
    height: 30px!important;
    margin-top:15px!important;
  }
  .dropZone{
    width: 300px!important;
    height: 150px!important;
  }
  .dropZone2{
    width: 300px!important;
    height: 150px!important;
  }
  .dropZone3{
    width: 300px!important;
    height: 150px!important;
  }
  .iconRange{
    margin-right: 10px!important;
    font-size: 10px!important;
  }
  .iconRange2{
    margin-right: 5px!important;
    font-size: 10px!important;
  }
}
@media screen and (max-width: 695px) {
  .arObjectText3{
    margin-left: 50px;
  }
}
@media screen and (max-width: 1077px) {
  .arrowDirection{
    max-width: 200px!important;
    max-height: 200px!important;
  }
  .iconBtnSize{
    max-width: 20px;
    max-height: 40px;
  }
  .assetTypeText{
    font-size: 14px;
    margin-top: -90px;
  }
  .assetTypeText2{
    font-size: 14px!important;
    margin-top: -90px!important;
    margin-right: -30px!important;
  }
}
@media screen and (max-width: 900px) {
  .arrowDirection{
    display:none;
  }
  .imgAssetResp{
    display:none;
  }
  .tdImg{
    display:none;
  }
  .tdArrow{
    display:none;
  }
  .tableAsset{
    text-align: center!important;
    display: flex;
    /* margin-left: 2000px!important; */
  }

  .triggerAssetText{
    display:  inline-block;
    visibility: visible;
  }
  .backIconImg2{
    width: 20px!important;
    height: 20px!important;
   } 
   .apiAction{
    margin-left: 0px!important;
  }
 
}
